import * as React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from 'react-bootstrap';
import { useState } from "react";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { variables } from '../Variables';
import { setMailVerificationDetail, clearMailVerificationDetail } from "../features/EmailVerificationSlice";

import closeIcon from '../assets/images/cross-close.svg';
import placeholderLarge from '../assets/images/prop-placeholder-lg.jpg';
import locationPin from '../assets/images/location-pin-grey.svg';
import bedIcon from '../assets/images/bed.svg';
import showerIcon from '../assets/images/p_shower.svg';
import liftIcon from '../assets/images/lift.svg';
import userIcon from '../assets/images/user-alt.svg';
import envelopeIcon from '../assets/images/envelope.svg';
import phoneBlack from '../assets/images/phone_black.svg';
import envelopeBlue from '../assets/images/envelope_blue.svg';
import mindfreeLogo from '../assets/images/Mindfree_Logo.svg';
import successTick from '../assets/images/success_tick.svg';

export const EventModal = ({
    showModal, handleClose, clientToken, eventSlot, estate, getAction,
    callbackGetToBeConfirmedIds, calendarActions, parent, resetSelectedSlot, officeId, clientId,
    clientSettings, time, formStep, setFormStep, prevSelectedEventSlot
}) => {
    const [configurations, setConfigurations] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [mobile, setMobile] = useState("");
    //const [formStep, setFormStep] = useState(1);
    let eventExist = false;

    const dispatch = useDispatch();

    const emailVerificationDetail = useSelector(state => state.emailVerification.mailVerificationDetail);

    const getConfigurations = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `InviteeConfiguration/GetConfigurations?eventId=` + eventSlot.id;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                setConfigurations(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const clickHandlerPreviewContactDetail = () => {
        let isFieldEmpty = false;
        if (name == "") {
            isFieldEmpty = true;
        }
        if (email == "") {
            isFieldEmpty = true;
        }
        if (firstName == "") {
            isFieldEmpty = true;
        }
        if (mobile == "") {
            isFieldEmpty = true;
        }
        if (isFieldEmpty == true) {
            alert("please fill the required fields");
            return;
        }
        setFormStep(2);
    }

    const handlerMoveToEmailVerification = () => {

        setFormStep(3);

        let code = Math.floor(Math.random() * 90000) + 10000;
        let expiry = new Date();
        expiry.setMinutes(expiry.getMinutes() + 20);

        let verificationCode = {
            code: code,
            validity: expiry.toISOString(),
            isMailSent: true
        }
        dispatch(setMailVerificationDetail(verificationCode));

        var html = "";
        html += "<div style='border: 1px solid #FAFAFA; background-color: white; border-radius: 16px; padding: 20px; width: 80%; margin: 0 auto; text-align: left'>"
        if (clientSettings != null && clientSettings.logoUrl != undefined) {
            html += "<img src='" + clientSettings.logoUrl + "' style='margin-bottom: 1.5rem' />";
        }
        else {
            html += "<img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/sidebar-logo.c2f1f3f5618f7e01cbfd4410196de7f5.svg' style='margin-bottom: 1.5rem; width: 200px' />";
        }
        html += "   <h5 style='font-size: 24px;font-weight: 700;color: #111827;font-family: Poppins; margin-bottom: 0.5rem'>Booking Verification</h5>";
        html += "   <p style='font-size: 16px;font-weight: 400;color: #000000;font-family: Poppins; margin-bottom: 1.5rem'>Thank you for using our booking.</p>"
        html += "   <div  style='margin-bottom: 1.5rem; display: flex; border: 1px solid #FAFAFA;border-radius: 8px; justify-content: space-between; padding: 14px 10px 14px 10px; align-items: center;'>"
        html += "      <span style='font-size: 14px;font-weight: 400;color: #000000;font-family: Poppins;width: 100%; border: 0; text-align: left;' >Your verification code is</span>"
        html += "      <span style='font-family: poppins; color: #CF132A; font-size: 22px'>" + code + "</span>"
        html += "   </div>"
        html += "</div> "

        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        let subject = "Mindfree Email Verification Code";

        let objEmail = {
            emailTo: email,
            body: html,
            subject: subject
        }

        const url = variables.API_URL + `Email/SendEmail?`;
        axios.post(url, JSON.stringify(objEmail), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;
                if (resp == "Email Sent successfully") {
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }

    const sendEmailToContact = async () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        let subject = "Your request for the visit of the property " + estate.id + " has been sent to the agency";

        //" + getAction(eventSlot.action.id) + "
        let html = "<div style='border: 1px solid #FAFAFA; background-color: white; border-radius: 16px; padding: 20px; width: 80%; margin: 0 auto; text-align: left'>"
        if (clientSettings != null && clientSettings.logoUrl != undefined) {
            html += "       <div><img src='" + clientSettings.logoUrl + "' style='margin-bottom: 1.5rem' /></div>";
        }
        else {
            html += "       <div><img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/sidebar-logo.c2f1f3f5618f7e01cbfd4410196de7f5.svg' style='margin-bottom: 1.5rem' /></div>";
        }
        html += "       <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/success_tick.42f810bca3513651d3c59926641fd3d3.svg' style='margin-bottom: 1.5rem' />"
        html += "       <h5 style='font-size: 24px;font-weight: 700;color: #111827;font-family: Poppins; margin-bottom: 1.5rem'>Congratulations your Visit request has been sent successfully to the agency. If they confirm your request, you will be automatically notified</h5>";
        html += "       <p style='font-size: 16px;font-weight: 400;color: #000000;font-family: Poppins; margin-bottom: 1.5rem; text-decoration: underline'>Resume of your booking request</p>";
        html += "       <div  style='margin-bottom: 1.5rem; border: 1px solid #FAFAFA;border-radius: 8px; text-align: left; padding: 15px;'>"
        html += "           <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem;'>Date</h5>";
        html += "           <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1.5rem'>" + new Date(eventSlot.startDateTime) + "</p>";
        html += "           <hr>";
        html += "           <div style='display:flex; margin-top: 1.5rem; margin-bottom: 1.5rem'>"
        html += "               <div style='border-right: 1px solid #9a9a9a; padding-right: 1rem'>"
        html += "                   <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem;'>Property</h5>";
        html += "                   <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + estate.name + "</p>";
        html += "                   <div style='display:flex; align-items: center; justify-content: left;'>"
        if (estate.pictures != undefined && estate.pictures.length > 0 && estate.pictures[0].urlLarge != undefined) {
            html += "                   <div><img src='" + estate.pictures[0].urlLarge + "' className='rounded-4' width='200px' alt='house image'></div>";
        }
        html += "                       <div style='padding: 10px; font-family: Poppins'>"
        html += "                           <p style='font-size: 14px; color: #111827; margin: 0; margin-bottom: 10px;'>" + estate.name + "</p>"
        html += "                           <p style='font-size: 11px; color: #A0AEC0; margin: 0; display: flex; align-items: center; margin-bottom: 12px'><img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/location-pin.6c12a9b55ca02e28e06c8a152c46cc4e.svg' style='margin-right: 0.5rem !important;' />" + (estate.address == undefined ? "" : estate.address) + " " + (estate.number == undefined ? "" : estate.number) + ", " + (estate.zip == undefined ? "" : estate.zip) + " " + (estate.city == undefined ? "" : estate.city) + "</p>"
        html += "                           <ul style='list-style: none; display: flex; align-items:center; justify-content: space-between; padding: 0; font-size: 11px; margin: 0; margin-bottom: 10px;'>"
        html += "                               <li style='border-right: 1px solid #000; text-align: left; display: flex; align-items: center; padding-right: 10px'>"
        html += "                                   <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/bed-duo.a88b91b71549bac113d8092f440ba522.svg'>"
        html += "                                       <span style='padding: 0 5px'>" + (estate.bedRooms == undefined ? "" : estate.bedRooms) + "</span>"
        html += "                               </li>"
        html += "                               <li style='display: flex; align-items: center; padding: 0 10px'>"
        html += "                                   <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/shower-duo.0cbb07c31e7baec8f9065786865ff709.svg'>"
        html += "                                       <span style='padding: 0 5px'>" + (estate.bathRooms == undefined ? "" : estate.bathRooms) + "</span>"
        html += "                               </li>"
        html += "                               <li style='border-left: 1px solid #000; display: flex; align-items: center; padding-left: 10px'>"
        html += "                                   <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/lift-duo.a90e49d9144e9660be65e9421e453663.svg'>"
        html += "                                       <span style='padding: 0 5px'>" + (estate.area == undefined ? "" : estate.area) + "</span>"
        html += "                               </li>"
        html += "                           </ul>"
        html += "                       </div>"
        html += "                   </div>"
        html += "               </div>"
        html += "               <div style='padding-left: 1rem;'>"
        html += "                   <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem;'>Contacts</h5>";
        html += "                   <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + name + "</p>";
        html += "                   <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + email + "</p>";
        html += "                   <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0;'>" + mobile + "</p>";
        html += "               </div>"
        html += "           </div>"
        html += "           <hr>";
        html += "       </div> "
        html += "</div> "
        let sendTo = email;
        //let sendTo = "adnanmustafa.n122@gmail.com";
        let objEmail = {
            emailTo: sendTo,
            body: html,
            subject: subject
        }

        const url = variables.API_URL + `Email/SendEmail?`;
        axios.post(url, JSON.stringify(objEmail), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;
                if (resp == "Email Sent successfully") {

                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const clickHandlerConfirmSlot = async (e) => {

        e.target.setAttribute("disabled", true);
        e.target.style.cursor = "progress";
        document.querySelector("body").style.cursor = "progress";

        let codeToVerify = document.getElementById("code").value;

        let validityDate = new Date(emailVerificationDetail.validity);
        let currentDateTime = new Date();
        if (codeToVerify == "") {
            alert("Please enter verification code that sent on your email");
            return;
        }

        if (emailVerificationDetail != null && currentDateTime < validityDate) {
            if (emailVerificationDetail.code !== +codeToVerify) {
                alert("Wrong code! please enter the correct code.");
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";;
                document.querySelector("body").style.cursor = "";
                return;
            }
            let body = {}
            body.CalendarIds = [+eventSlot.id]
            await getWhiseCalenderEventById(body);
            if (eventExist) {
                dispatch(clearMailVerificationDetail());
                let contactbody = {
                    Name: name,
                    PrivateEmail: email,
                    OfficeIds: [+officeId],
                    Number: mobile,
                    FirstName: firstName,
                    CountryId: 1,
                    StatusId: 1,
                    FunnelStatusId: 3,
                    LanguageId: 'fr-BE'
                };
                addContactOnWhise(contactbody, e);
            } else {
                alert("This Slot is already booked and not available please try an Other slot");
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";;
                document.querySelector("body").style.cursor = "";
            }
        }
    }

    const addContactOnWhise = async (body, btnRef) => {
        if (clientToken != "") {
            let url = "https://api.whise.eu/v1/contacts/upsert";
            const config = {
                headers: {
                    'Authorization': `Bearer ${clientToken}`,
                    'Content-Type': 'application/json'
                },
            };

            axios.post(url, body, config)
                .then(response => {
                    if (response.data.contactId > 0) {
                        deleteFreeSlotAddTobeConformWhiseCalenerEvent(response.data.contactId, btnRef, body);
                    }
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    }

    const deleteFreeSlotAddTobeConformWhiseCalenerEvent = (contactId, btnRef, contactBody) => {
        let url = "https://api.whise.eu/v1/calendars/delete";
        let headers = {
            'Authorization': `Bearer ${clientToken}`,
            'Content-Type': 'application/json'
        };
        let body = {
            "CalendarIds": [eventSlot.id]
        };
        axios.delete(url, { data: body, headers: headers })
            .then(response => {
                parent();
                if (response.data) {
                    let address = "";
                    address += estate.address == undefined ? "" : estate.address + " ";
                    address += estate.number == undefined ? "" : estate.number + ", ";
                    address += estate.zip == undefined ? "" : estate.zip;
                    address += estate.city == undefined ? "" : " " + estate.city;

                    let action = calendarActions.filter(item => {
                        return item.id == eventSlot.action.id;
                    })

                    let subject = action[0].name + ": " + address + " " + contactBody.Name;

                    let configration = {
                        "StartDateTime": eventSlot.startDateTime,
                        "EndDateTime": eventSlot.endDateTime,
                        "AllDay": "false",
                        "Private": false,
                        "subject": subject,
                        "UserIds": [eventSlot.users[0].userId],
                        "EstateIds": [estate.id],
                        "ContactIds": [contactId]
                    }
                    configration["CalendarActionId"] = callbackGetToBeConfirmedIds(estate.purpose.id);

                    addWhiseCalenderEvent(configration, contactId, contactBody.Name, btnRef);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const addWhiseCalenderEvent = async (body, contactId, contactName, btnRef) => {
        let url = "https://api.whise.eu/v1/calendars/upsert";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, body, config)
            .then(response => {
                const call1 = sendEmailToContact();  //email to contact
                const call2 = sendEmail(response.data.calendarId, contactId, contactName);    //email to agent
                Promise.all([call1, call2]).then(() => {
                    setFormStep(4);
                    resetSelectedSlot();
                    btnRef.target.removeAttribute("disabled");
                    btnRef.target.style.cursor = "";
                    document.querySelector("body").style.cursor = "";
                });
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const sendEmail = (eventId, contactId, contactName) => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        let subject = "Please Confirm the Booking";
        let externalUrl = variables.AgentSiteURL + "eventhandler/" + contactId + "/" + eventId + "/" + clientId + "/" + officeId + "/`" + contactName + "`";

        let html = "<div style='border: 1px solid #FAFAFA; background-color: white; border-radius: 16px; padding: 20px; width: 80%; margin: 0 auto; text-align: left'>"
        if (clientSettings != null && clientSettings.logoUrl != undefined) {
            html += "       <div><img src='" + clientSettings.logoUrl + "' style='margin-bottom: 1.5rem' /></div>";
        }
        else {
            html += "       <div><img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/sidebar-logo.c2f1f3f5618f7e01cbfd4410196de7f5.svg' style='margin-bottom: 1.5rem' /></div>";
        }
        html += "   <h5 style='font-size: 24px;font-weight: 500;color: #111827;font-family: Poppins; margin-bottom: 0.5rem'>A Visit request has been booked trough the SmartCalendar online visit planner for:</h5>";
        html += "   <div  style='margin-bottom: 1.5rem; border: 1px solid #FAFAFA;border-radius: 8px; text-align: left; padding: 15px;'>"
        html += "       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>Contacts</h5>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + name + "</p>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + email + "</p>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1.5rem'>" + mobile + "</p>";
        html += "       <hr>";
        html += "       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem; margin-top: 1.5rem'>Property</h5>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + estate.name + "</p>";
        html += "       <div style='display:flex; align-items: center; justify-content: left; margin-bottom: 1.5rem'>"
        if (estate.pictures != undefined && estate.pictures.length > 0 && estate.pictures[0].urlLarge != undefined) {
            html += "       <div><img src='" + estate.pictures[0].urlLarge + "' className='rounded-4' width='200px' alt='house image'></div>";
        }
        html += "           <div style='padding: 10px; font-family: Poppins'>"
        html += "               <p style='font-size: 14px; color: #111827; margin: 0; margin-bottom: 10px;'>" + estate.name + "</p>"
        html += "               <p style='font-size: 11px; color: #A0AEC0; margin: 0; display: flex; align-items: center; margin-bottom: 12px'><img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/location-pin.6c12a9b55ca02e28e06c8a152c46cc4e.svg' style='margin-right: 0.5rem !important;' />" + (estate.address == undefined ? "" : estate.address) + " " + (estate.number == undefined ? "" : estate.number) + ", " + (estate.zip == undefined ? "" : estate.zip) + " " + (estate.city == undefined ? "" : estate.city) + "</p>"
        html += "               <ul style='list-style: none; display: flex; align-items:center; justify-content: space-between; padding: 0; font-size: 11px; margin: 0; margin-bottom: 10px;'>"
        html += "                   <li style='border-right: 1px solid #000; text-align: left; display: flex; align-items: center; padding-right: 10px'>"
        html += "                       <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/bed-duo.a88b91b71549bac113d8092f440ba522.svg'>"
        html += "                           <span style='padding: 0 5px'>" + (estate.bedRooms == undefined ? "" : estate.bedRooms) + "</span>"
        html += "                   </li>"
        html += "                   <li style='display: flex; align-items: center; padding: 0 10px'>"
        html += "                       <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/shower-duo.0cbb07c31e7baec8f9065786865ff709.svg'>"
        html += "                           <span style='padding: 0 5px'>" + (estate.bathRooms == undefined ? "" : estate.bathRooms) + "</span>"
        html += "                   </li>"
        html += "                   <li style='border-left: 1px solid #000; display: flex; align-items: center; padding-left: 10px'>"
        html += "                       <img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/lift-duo.a90e49d9144e9660be65e9421e453663.svg'>"
        html += "                           <span style='padding: 0 5px'>" + (estate.area == undefined ? "" : estate.area) + "</span>"
        html += "                   </li>"
        html += "               </ul>"
        html += "           </div>"
        html += "       </div>"
        html += "       <hr>";
        html += "       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem; margin-top: 1.5rem'>Date</h5>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + new Date(eventSlot.startDateTime) + "</p>";
        html += "   </div> "
        html += "   <div style='display: flex; align-items: center; justify-content: space-between; font-family: Poppins'>"
        html += "       <a href='#' style='margin-right: 10px; border: 1px solid #111827; text-decoration: none; width: 100%; padding: 9px 10px; border-radius: 8px; text-align: center; color: #111827; font-size: 14px'>Refuse</a>"
        html += "       <a href='" + externalUrl + "' style='background-color: #274B97; color: white; text-decoration: none; width: 100%; padding: 10px; border-radius: 8px; text-align: center;font-size: 14px'>Accept</a>"
        html += "   </div>"
        html += "</div> "

        let sendTo = eventSlot.users[0].email;
        //let sendTo = "adnanmustafa.n122@gmail.com";

        let objEmail = {
            emailTo: sendTo,
            body: html,
            subject: subject
        }

        const url = variables.API_URL + `Email/SendEmail?`;
        axios.post(url, JSON.stringify(objEmail), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;
                if (resp == "Email Sent successfully") {
                }
                //handleClose();

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseCalenderEventById = async (filters) => {
        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };
        let body = {
            "Filter": filters
        };
        await axios.post(url, body, config)
            .then(response => {
                if (response.data.calendars) {
                    eventExist = true;
                } else {
                    eventExist = false;
                }

            })
            .catch(error => {
                console.log("error", error);
            });
    }

    const onChangeMobileHandler = (e) => {
        let mobileNumber = "+" + e;
        setMobile(mobileNumber);
    }

    useEffect(() => {
        if (eventSlot != null) {
            getConfigurations();
        }
    }, [eventSlot])

    useEffect(() => {
        if (showModal == true && prevSelectedEventSlot != null && eventSlot.id !== prevSelectedEventSlot.id) {
            setName("");
            setEmail("");
            setFirstName("");
            setMobile("");
        }
    }, [showModal])

    useEffect(() => {
        if (formStep === 4) {
            setName("");
            setEmail("");
            setFirstName("");
            setMobile("");
        }
    }, [formStep])

    return (
        <>
            <Modal show={showModal} onHide={handleClose} className="modalStyle">
                <Modal.Body className="p-0">
                    <div className="modal-container">
                        {
                            formStep == 1
                                ?
                                <>
                                    <div className="d-flex justify-content-between mb-4">
                                        <p className="form-title"> Booking {time != null ? "(" + time + ")" : ""}</p>
                                        <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
                                    </div>
                                    <div className="mb-4">
                                        <div className="form-group mb-4">
                                            <label className="form-group-label mt-0">Name<span>*</span></label>
                                            <input type="text" id="name" className="form-control w-100" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="form-group-label mt-0">First Name<span>*</span></label>
                                            <input type="text" id="firstName" className="form-control w-100" placeholder="Frist name" onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="form-group-label mt-0">Email<span>*</span></label>
                                            <input type="text" id="email" className="form-control w-100" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="form-group-label mt-0">Mobile<span>*</span></label>
                                            <PhoneInput
                                                country={'be'}
                                                onChange={onChangeMobileHandler}
                                                onlyCountries={['be']}
                                                enableAreaCodes={true}
                                                countryCodeEditable={false}

                                            />
                                            {/*<input type="text" id="mobile" className="form-control w-100" placeholder="Mobile" onChange={(e) => setMobile(e.target.value)} />*/}
                                        </div>
                                        {/*<div className="form-group mb-4">*/}
                                        {/*    <label className="form-group-label mt-0">Gener<span>*</span></label>*/}
                                        {/*    <select id="gender" className="form-control w-100" onChange={(e) => setMobile(e.target.value)}>*/}
                                        {/*        <option value="">Select Gender</option>*/}
                                        {/*        <option value="1">Mr</option>*/}
                                        {/*        <option value="2">Mrs</option>*/}
                                        {/*        <option value="3">MrAndMrs</option>*/}
                                        {/*        <option value="4">Other</option>*/}
                                        {/*        <option value="5">Master</option>*/}
                                        {/*        <option value="6">Master</option>*/}
                                        {/*    </select>*/}
                                        {/*</div>*/}

                                        {/*{*/}
                                        {/*    configurations != null && configurations.name == true ?*/}
                                        {/*        <div className="form-group mb-4">*/}
                                        {/*            <label className="form-group-label mt-0">Name<span>*</span></label>*/}
                                        {/*            <input type="text" id="name" className="form-control w-100" placeholder="Name" onChange={(e) => setName(e.target.value)} />*/}
                                        {/*        </div>*/}
                                        {/*        :*/}
                                        {/*        <></>*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    configurations != null && configurations.firstName == true ?*/}
                                        {/*        <div className="form-group mb-4">*/}
                                        {/*            <label className="form-group-label mt-0">First Name<span>*</span></label>*/}
                                        {/*            <input type="text" id="firstName" className="form-control w-100" placeholder="Frist name" onChange={(e) => setFirstName(e.target.value)} />*/}
                                        {/*        </div>*/}
                                        {/*        :*/}
                                        {/*        <></>*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    configurations != null && configurations.email == true ?*/}
                                        {/*        <div className="form-group mb-4">*/}
                                        {/*            <label className="form-group-label mt-0">Email<span>*</span></label>*/}
                                        {/*            <input type="text" id="email" className="form-control w-100" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />*/}
                                        {/*        </div>*/}
                                        {/*        :*/}
                                        {/*        <></>*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    configurations != null && configurations.mobile == true ?*/}
                                        {/*        <div className="form-group mb-4">*/}
                                        {/*            <label className="form-group-label mt-0">Mobile<span>*</span></label>*/}
                                        {/*            <input type="text" id="mobile" className="form-control w-100" placeholder="Mobile" onChange={(e) => setMobile(e.target.value)} />*/}
                                        {/*        </div>*/}
                                        {/*        :*/}
                                        {/*        <></>*/}
                                        {/*}*/}
                                        {
                                            configurations != null && configurations.question == true ?
                                                <div className="form-group mb-4">
                                                    <label className="form-group-label mt-0">Question</label>
                                                    <input type="text" id="question" className="form-control w-100" placeholder="Question" />
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            configurations != null && configurations.documents == true ?
                                                <div className="form-group mb-4">
                                                    <label className="form-group-label mt-0">Documents</label>
                                                    <input type="text" id="documents" className="form-control w-100" placeholder="Documents" />
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            configurations != null && configurations.agentRecap == true ?
                                                <div className="form-group mb-4">
                                                    <label className="form-group-label mt-0">Agent Recap</label>
                                                    <input type="text" id="agentRecap" className="form-control w-100" placeholder="Recap of the agent on site" />
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className="d-flex">
                                        <button type="button" className="site-btn site-btn-padd w-100" onClick={clickHandlerPreviewContactDetail}>Next</button>
                                    </div>
                                </>
                                : formStep == 2 ?
                                    <>
                                        <div className="d-flex justify-content-between mb-4">
                                            <p className="form-title"> Booking </p>
                                            <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
                                        </div>
                                        <div className="d-flex form-container form-container-expanded room-info mb-4">
                                            <div className="w-50">
                                                {
                                                    estate != null && estate.pictures != undefined && estate.pictures.length > 0
                                                        ?
                                                        <img src={estate.pictures[0].urlLarge} className="property-img" />
                                                        :
                                                        <img src={placeholderLarge} className="property-img" />

                                                }
                                            </div>
                                            <div className="ms-2 py-3">
                                                <p className="mb-0 property-name ">
                                                    {
                                                        estate != null ?
                                                            estate.name
                                                            :
                                                            "Property Name"
                                                    }
                                                </p>
                                                <div className="prop-address d-flex align-items-center mt-1">
                                                    <img src={locationPin} className="me-1" />
                                                    {
                                                        estate != null ?
                                                            <>
                                                                <p className="mb-0">
                                                                    {
                                                                        (estate.address == undefined ? "" : estate.address) + " " +
                                                                        (estate.number == undefined ? "" : estate.number) + ", " +
                                                                        (estate.zip == undefined ? "" : estate.zip) + " " +
                                                                        (estate.city == undefined ? "" : estate.city)
                                                                    }
                                                                </p>
                                                            </>
                                                            //<p className="mb-0">{estate.address + " " + estate.number + ", " + estate.zip + " " + estate.city}</p>
                                                            :
                                                            <p className="mb-0">Address</p>
                                                    }
                                                </div>
                                                <div className="row prop-detail-row mt-3">
                                                    <div className="col-4 d-flex align-items-center justify-content-center border-end">
                                                        <img src={bedIcon} className={`${estate != null && estate.bedRooms != undefined ? "me-1" : ""} small-img-modal`} />
                                                        <span className="smallImgIcon-Modal-info">
                                                            {
                                                                estate != null && estate.bedRooms != undefined ?
                                                                    estate.bedRooms
                                                                    :
                                                                    <></>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="col-4 d-flex align-items-center justify-content-center border-end">
                                                        <img src={showerIcon} className={`${estate != null && estate.bathRooms != undefined ? "me-1" : ""} small-img-modal`} />
                                                        <span className="smallImgIcon-Modal-info">
                                                            {
                                                                estate != null && estate.bathRooms != undefined ?
                                                                    estate.bathRooms
                                                                    :
                                                                    <></>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="col-4 d-flex align-items-center">
                                                        <img src={liftIcon} className={`${estate != null && estate.area != undefined ? "me-1" : ""} small-img-modal`} />
                                                        {
                                                            estate != null && estate.area != undefined ?
                                                                <span className="smallImgIcon-Modal-info">{estate.area}<span className="ms-05">m</span><sup>2</sup></span>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4">
                                            <p className="subtitle mb-0"><img src={userIcon} className="me-2" /> Full Name</p>
                                            <div className="text-end">
                                                {
                                                    name != "" ?
                                                        <p className="subtitle mb-0">{name}</p>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4">
                                            <p className="subtitle mb-0"><img src={envelopeIcon} className="me-2" /> Email</p>
                                            <div className="text-end">
                                                {
                                                    email != "" ?
                                                        <p className="subtitle mb-0">{email}</p>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center border-bottom-end pb-4 border-bottom">
                                            <p className="subtitle mb-0"> <img src={phoneBlack} className="me-2" /> Mobile</p>
                                            <div className="text-end">
                                                {
                                                    mobile != "" ?
                                                        <p className="subtitle mb-0">{mobile}</p>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4 pt-4">
                                            <p className="subtitle mb-0"><img src={userIcon} className="me-2" /> Agent</p>
                                            <div className="text-end">
                                                {
                                                    eventSlot != null && eventSlot.users.length > 0 ?
                                                        eventSlot.users.map(item => {
                                                            return (
                                                                <p className="subtitle mb-0">{item.name}</p>
                                                            )
                                                        })
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" onClick={handleClose}>Cancel</button>
                                            <button className="site-btn site-btn-padd w-100" onClick={handlerMoveToEmailVerification}>Next</button>
                                        </div>
                                    </>
                                    :
                                    formStep == 3 ?
                                        <div className="text-center">
                                            <p className="verify-code-circle text-center mb-4 m-auto">
                                                <img src={envelopeBlue} />
                                            </p>
                                            <h5 className="modal-big-heading text-center">Booking Verification</h5>
                                            <p className="modal-p mb-4">Please enter the code sent to your email address to complete the booking process.</p>
                                            <div className="form-group mb-4">
                                                <label className="form-group-label mt-0" htmlFor="code">Code<span>*</span></label>
                                                <input type="text" id="code" className="form-control w-100" placeholder="Verification code" />
                                            </div>
                                            <button className="site-btn site-btn-padd w-100 mb-4" onClick={clickHandlerConfirmSlot}>Verify</button>
                                            <p className="small-grey-text mt-1 mb-1">Didn’t get the code?</p>
                                            <a href="" className="link">Resend</a>
                                        </div>
                                        :
                                        <>
                                            <div className="d-flex justify-content-between align-items-start mb-4">
                                                {
                                                    clientSettings != null && clientSettings.logoUrl != undefined ?
                                                        <img src={clientSettings.logoUrl} width="200" />
                                                        :
                                                        <img src={mindfreeLogo} />
                                                }
                                                <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
                                            </div>
                                            <div className="message-container">
                                                <img src={successTick} className="mb-4" />
                                                <p className="success-message mb-4">Congratulations your visit request has been booked successfully</p>
                                                <p className="thanks-message">Thank you for using our booking.</p>
                                            </div>
                                        </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}





