export const variables = {
    API_URL: "https://mink-smartcalendar-api.net7.be/api/", //"https://localhost:7118/api/", //"http://localhost:40445/api/",
    AgentSiteURL: "https://mink-smartcalendar-agent-ui.net7.be/", //"https://localhost:44436/", //
    //ContactId: "32065699", //"31829918", //
    WhiseUser: "dev.smart@mindfree.be", //"dev@realadvice.be",//
    WhisePassword: "pa$$worD1"
};

export const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);